#coverList{
    max-width:1200px;
    margin:auto;
    margin-top:100px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 2em;
    column-gap: 2em;
}

.cover{
    position: relative;
}

.cover img{
    width: 380px;
    height: 380px;
}

.coverTitleHolder{
    position: absolute;
    opacity: 0;
    background:black;
    text-align: center;
    transition: all 0.3s ease;
    width:100%;
    height:100%;
}
.coverTitle{
    top:50%;
    position: absolute;
    transform: translate(-50%,-50%);
    font-size:1.2em;
}

.cover:hover .coverTitleHolder{
    opacity: 1;
    cursor: pointer;
}


@media screen and (max-width: 1350px)
{
    #coverList{
        width:80%;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 1000px)
{
    #coverList{
        width:90%;
        grid-template-columns: repeat(1, 1fr);
    }
    .cover img{
        width: 100% !important;
        height: 100% !important;
    }
}