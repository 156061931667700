#menu{
    margin:auto;
    padding-top:50px;
}
.logoContainer{
    display:inline-block;
    margin-left:50%;
    transform: translateX(-50%);
}

.nav{
    padding-top:50px;
}
.tabWrapper{
    display: inline-block;
    margin-left:50%;
    transform: translateX(-50%);
}
.tab{
    display: inline-block;
    margin: 0px 15px 0px 15px ;
    letter-spacing: 0.05em;
}

.tab:hover{
    color:rgb(236, 236, 236);
    cursor: pointer;

}

a{
    color: rgb(158, 158, 158);
    text-decoration: none;
}
a:hover{
    color:rgb(241, 239, 239);
}

