#project{
    background : black;
    color: rgb(158, 158, 158);
    min-height:100vh;
}

.projectHolder{
    width:1000px;
    margin:auto;
    margin-top:100px;
    display: grid;
    grid-template-columns: 30fr 70fr;
}

/*.projectCover, .projectDescription{
    display: inline-block;
}*/

.projectCover{
    position: relative;
}
.projectCover img{
    width:100%;
    top:0%;

}

.nameAndReleaseDate{
    
}

.projectDescription{
    padding-left: 50px;
}

#embedIFrame{
    width: 100%;
    margin:auto;
    margin-top:50px;
}

@media screen and (max-width: 1100px)
{
    .projectHolder{
        width:90%;
        grid-template-columns: 1fr;
    }

    .projectDescription{
        padding-left: 0px;
        margin-top:50px;
    }

    .nameAndReleaseDate{
        margin-top:20px;
    }
    
}