@import url(https://fonts.googleapis.com/css2?family=JetBrains+Mono&display=swap);
body {
  margin: 0;
  font-family: 'JetBrains Mono', monospace,
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#menu{
    margin:auto;
    padding-top:50px;
}
.logoContainer{
    display:inline-block;
    margin-left:50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.nav{
    padding-top:50px;
}
.tabWrapper{
    display: inline-block;
    margin-left:50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}
.tab{
    display: inline-block;
    margin: 0px 15px 0px 15px ;
    letter-spacing: 0.05em;
}

.tab:hover{
    color:rgb(236, 236, 236);
    cursor: pointer;

}

a{
    color: rgb(158, 158, 158);
    text-decoration: none;
}
a:hover{
    color:rgb(241, 239, 239);
}


#footer{

    font-size:.8em;
    width:100%;
    text-align: center;
    margin:auto;    
    margin-top:70px;
    padding-bottom:70px;
}
#coverList{
    max-width:1200px;
    margin:auto;
    margin-top:100px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 2em;
    row-gap: 2em;
    grid-column-gap: 2em;
    -webkit-column-gap: 2em;
            column-gap: 2em;
}

.cover{
    position: relative;
}

.cover img{
    width: 380px;
    height: 380px;
}

.coverTitleHolder{
    position: absolute;
    opacity: 0;
    background:black;
    text-align: center;
    transition: all 0.3s ease;
    width:100%;
    height:100%;
}
.coverTitle{
    top:50%;
    position: absolute;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    font-size:1.2em;
}

.cover:hover .coverTitleHolder{
    opacity: 1;
    cursor: pointer;
}


@media screen and (max-width: 1350px)
{
    #coverList{
        width:80%;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 1000px)
{
    #coverList{
        width:90%;
        grid-template-columns: repeat(1, 1fr);
    }
    .cover img{
        width: 100% !important;
        height: 100% !important;
    }
}
#project{
    background : black;
    color: rgb(158, 158, 158);
    min-height:100vh;
}

.projectHolder{
    width:1000px;
    margin:auto;
    margin-top:100px;
    display: grid;
    grid-template-columns: 30fr 70fr;
}

/*.projectCover, .projectDescription{
    display: inline-block;
}*/

.projectCover{
    position: relative;
}
.projectCover img{
    width:100%;
    top:0%;

}

.nameAndReleaseDate{
    
}

.projectDescription{
    padding-left: 50px;
}

#embedIFrame{
    width: 100%;
    margin:auto;
    margin-top:50px;
}

@media screen and (max-width: 1100px)
{
    .projectHolder{
        width:90%;
        grid-template-columns: 1fr;
    }

    .projectDescription{
        padding-left: 0px;
        margin-top:50px;
    }

    .nameAndReleaseDate{
        margin-top:20px;
    }
    
}

.mix-list {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
}

*{margin:0;padding:0;}

#App {
  color: rgb(158, 158, 158);
  min-height: 100vh;
  background: black;
}

@font-face {
    font-family: OratorSTD;
    src: url(../../static/media/oratorstd.67512010.otf) format("opentype");
}
@font-face {
    font-family: Fabrikat;
    src: url(../../static/media/Fabrikat.c9f2a774.otf) format("opentype");
}

